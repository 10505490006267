
// Typography
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// 
// Variables
// 
$white: #fffffd;

$theme-colors: (
    "light":      #e8e3e1,
    "dark":       #020302,
    "primary":    #5e3bb8,
    "secondary":  #636ad6,
    "info":       #5d8bec,
    "accent1":    #413f55,
    "accent2":    #715994,
    "accent3":    #a4a4c3,
    "success":    #23a264,
    "warning":    #dfcb23,
    "danger":     #ec2911,
);


// Import Bootstrap variables for use within theme
@import "bootstrap/scss/functions.scss";
@import "bootstrap/scss/variables.scss";

// Import spacing variables
@import "./variables/spacing.scss";

// Import navigation variables
@import "./variables/navigation.scss";

// TailwindCss Size
@import "./tailwindsize";