// 
// Global
// 

html,
body {
    height: 100%;
}

// Add SASS theme customizations here..

$enable-grid-classes:false;
$primary:#563d7c !important;
$secondary:#868e96 !important;
$success:#28a745 !important;
$danger:#dc3545 !important;
$info:#17a2b8 !important;
$warning:#ffc107 !important;
$light:#f8f9fa !important;
$dark:#343434 !important;
$enable-gradients:false;
$spacer:1rem;
$border-width:1px;
$btn-border-radius:.25rem;
