

.w-0 {
    width: 0px
  }
  
  .w-1 {
    width: 0.25rem
  }
  
  .w-2 {
    width: 0.5rem
  }
  
  .w-3 {
    width: 0.75rem
  }
  
  .w-4 {
    width: 1rem
  }
  
  .w-5 {
    width: 1.25rem
  }
  
  .w-6 {
    width: 1.5rem
  }
  
  .w-7 {
    width: 1.75rem
  }
  
  .w-8 {
    width: 2rem
  }
  
  .w-9 {
    width: 2.25rem
  }
  
  .w-10 {
    width: 2.5rem
  }
  
  .w-11 {
    width: 2.75rem
  }
  
  .w-12 {
    width: 3rem
  }
  
  .w-14 {
    width: 3.5rem
  }
  
  .w-16 {
    width: 4rem
  }
  
  .w-20 {
    width: 5rem
  }
  
  .w-24 {
    width: 6rem
  }
  
  .w-28 {
    width: 7rem
  }
  
  .w-32 {
    width: 8rem
  }
  
  .w-36 {
    width: 9rem
  }
  
  .w-40 {
    width: 10rem
  }
  
  .w-44 {
    width: 11rem
  }
  
  .w-48 {
    width: 12rem
  }
  
  .w-52 {
    width: 13rem
  }
  
  .w-56 {
    width: 14rem
  }
  
  .w-60 {
    width: 15rem
  }
  
  .w-64 {
    width: 16rem
  }
  
  .w-72 {
    width: 18rem
  }
  
  .w-80 {
    width: 20rem
  }
  
  .w-96 {
    width: 24rem
  }
  
  .w-auto {
    width: auto
  }
  
  .w-px {
    width: 1px
  }
  
  .w-0\.5 {
    width: 0.125rem
  }
  
  .w-1\.5 {
    width: 0.375rem
  }
  
  .w-2\.5 {
    width: 0.625rem
  }
  
  .w-3\.5 {
    width: 0.875rem
  }
  
  .w-1\/2 {
    width: 50%
  }
  
  .w-1\/3 {
    width: 33.333333%
  }
  
  .w-2\/3 {
    width: 66.666667%
  }
  
  .w-1\/4 {
    width: 25%
  }
  
  .w-2\/4 {
    width: 50%
  }
  
  .w-3\/4 {
    width: 75%
  }
  
  .w-1\/5 {
    width: 20%
  }
  
  .w-2\/5 {
    width: 40%
  }
  
  .w-3\/5 {
    width: 60%
  }
  
  .w-4\/5 {
    width: 80%
  }
  
  .w-1\/6 {
    width: 16.666667%
  }
  
  .w-2\/6 {
    width: 33.333333%
  }
  
  .w-3\/6 {
    width: 50%
  }
  
  .w-4\/6 {
    width: 66.666667%
  }
  
  .w-5\/6 {
    width: 83.333333%
  }
  
  .w-1\/12 {
    width: 8.333333%
  }
  
  .w-2\/12 {
    width: 16.666667%
  }
  
  .w-3\/12 {
    width: 25%
  }
  
  .w-4\/12 {
    width: 33.333333%
  }
  
  .w-5\/12 {
    width: 41.666667%
  }
  
  .w-6\/12 {
    width: 50%
  }
  
  .w-7\/12 {
    width: 58.333333%
  }
  
  .w-8\/12 {
    width: 66.666667%
  }
  
  .w-9\/12 {
    width: 75%
  }
  
  .w-10\/12 {
    width: 83.333333%
  }
  
  .w-11\/12 {
    width: 91.666667%
  }
  
  .w-min {
    width: min-content
  }
  
  .w-max {
    width: max-content
  }
  
  
.h-0 {
    height: 0px
  }
  
  .h-1 {
    height: 0.25rem
  }
  
  .h-2 {
    height: 0.5rem
  }
  
  .h-3 {
    height: 0.75rem
  }
  
  .h-4 {
    height: 1rem
  }
  
  .h-5 {
    height: 1.25rem
  }
  
  .h-6 {
    height: 1.5rem
  }
  
  .h-7 {
    height: 1.75rem
  }
  
  .h-8 {
    height: 2rem
  }
  
  .h-9 {
    height: 2.25rem
  }
  
  .h-10 {
    height: 2.5rem
  }
  
  .h-11 {
    height: 2.75rem
  }
  
  .h-12 {
    height: 3rem
  }
  
  .h-14 {
    height: 3.5rem
  }
  
  .h-16 {
    height: 4rem
  }
  
  .h-20 {
    height: 5rem
  }
  
  .h-24 {
    height: 6rem
  }
  
  .h-28 {
    height: 7rem
  }
  
  .h-32 {
    height: 8rem
  }
  
  .h-36 {
    height: 9rem
  }
  
  .h-40 {
    height: 10rem
  }
  
  .h-44 {
    height: 11rem
  }
  
  .h-48 {
    height: 12rem
  }
  
  .h-52 {
    height: 13rem
  }
  
  .h-56 {
    height: 14rem
  }
  
  .h-60 {
    height: 15rem
  }
  
  .h-64 {
    height: 16rem
  }
  
  .h-72 {
    height: 18rem
  }
  
  .h-80 {
    height: 20rem
  }
  
  .h-96 {
    height: 24rem
  }
  
  .h-auto {
    height: auto
  }
  
  .h-px {
    height: 1px
  }
  
  .h-0\.5 {
    height: 0.125rem
  }
  
  .h-1\.5 {
    height: 0.375rem
  }
  
  .h-2\.5 {
    height: 0.625rem
  }
  
  .h-3\.5 {
    height: 0.875rem
  }
  
  .h-1\/2 {
    height: 50%
  }
  
  .h-1\/3 {
    height: 33.333333%
  }
  
  .h-2\/3 {
    height: 66.666667%
  }
  
  .h-1\/4 {
    height: 25%
  }
  
  .h-2\/4 {
    height: 50%
  }
  
  .h-3\/4 {
    height: 75%
  }
  
  .h-1\/5 {
    height: 20%
  }
  
  .h-2\/5 {
    height: 40%
  }
  
  .h-3\/5 {
    height: 60%
  }
  
  .h-4\/5 {
    height: 80%
  }
  
  .h-1\/6 {
    height: 16.666667%
  }
  
  .h-2\/6 {
    height: 33.333333%
  }
  
  .h-3\/6 {
    height: 50%
  }
  
  .h-4\/6 {
    height: 66.666667%
  }
  
  .h-5\/6 {
    height: 83.333333%
  }
  
  
  .z-0 {
    z-index: 0
  }
  
  .z-10 {
    z-index: 10
  }
  
  .z-20 {
    z-index: 20
  }
  
  .z-30 {
    z-index: 30
  }
  
  .z-40 {
    z-index: 40
  }
  
  .z-50 {
    z-index: 50
  }
  
  .z-auto {
    z-index: auto
  }

  .h-full {
    height: 100%
  }
  
  .h-screen {
    height: 100vh
  }